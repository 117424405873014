<template>
  <div>

    <div class="card">
      <div class="card-body">
        <button type="button" style="float: right"  data-bs-toggle="modal" data-bs-target="#createBrandModal" class="btn btn-primary px-5">New Brand</button>
        <pagination @search="loadBrands" :data="pagination"></pagination>

        <table id="example" class="table table-hover" style="width:100%">
          <thead>
            <tr>
              <th><!-- LOGO --></th>
              <th>Name</th>
              <th># Sites</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="brand in brands" :key="brand.id" style="cursor: pointer;" @click="$router.push({name: 'admin.brands.view', params: {id: brand.id}})">
              <td><secure-image v-if="brand.logo" style="height: 1rem;" :id="brand.name + '-logo'" :endpoint="`/v1/brands/${brand.id}/logo`"></secure-image></td>
              <td>{{brand.name}}</td>
              <td>#{{brand.SiteCount}}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th><!-- LOGO --></th>
              <th>Name</th>
              <th># Sites</th>
            </tr>
          </tfoot>
        </table>

        <pagination @search="loadBrands" :data="pagination"></pagination>
      </div>
    </div>

    <!-- MODALS -->

    <div class="modal fade" id="createBrandModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create Brand</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 mb-2">
                <p>Fields marked <span class="text-danger">*</span> are required</p>
              </div>
              <div class="col-md-12 mb-2">
                <label for="brandName" class="form-label">Name <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="brandName" v-model="brand.name">
              </div>
              <div class="col-md-12 mb-2">
                <label for="brandLogo" class="form-label">Logo</label>
                <input type="file" accept="image/*" ref="file" id="brandLogo" @change="selectFile" class="form-control">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeCreateBrandModal'>Close</button>
            <submit-button type="button" :background="'btn btn-primary'" :clicked="creating" @click="createBrand" text="Save changes"></submit-button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
/* global $ */
import axios from 'axios';
import Pagination from '../../../components/Pagination.vue';
import SubmitButton from '../../../components/SubmitButton.vue';
import SecureImage from '../../../components/SecureImage.vue';
export default {
  components: { Pagination, SubmitButton, SecureImage },
  name: 'admin.brands',
  data(){
    return {
      brands: [],
      pagination: {
        lastPage: 0,
        page: 0,
        showing: 0,
        total: 0
      },
      brand: {
        name: '',
        logo: null
      },
      creating: false
    }
  },
  mounted(){
    this.loadBrands()
  },
  methods: {
    loadBrands(page = 1){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/brands?page=${page}`)
      .then(response => {
        this.brands = response.data.brands;
        this.pagination = response.data.pagination;
      })
      .catch(error => {
        this.$error('Failed to load brands.', error);
      });
    },
    selectFile(){
      this.brand.logo = this.$refs.file.files[0];
      console.log(this.brand.logo);
    },
    createBrand(){
      this.creating = true;
      let data = new FormData();
      data.append('name', this.brand.name);
      if(this.brand.logo !== null){
        data.append('logo', this.brand.logo, this.logo_filename);
      }
      axios.put(`${process.env.VUE_APP_API_URL}/v1/brands`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        $('#closeCreateBrandModal').click();
        this.$success("Created new brand");
        this.creating = false;
        this.brand = {
          name: '',
          logo: null
        }
        this.loadBrands(this.pagination.page);
      })
      .catch(error => {
        this.$error("Failed to create new brand.", error);
        this.creating = false;
      })
    }

  },
  computed: {
    logo_filename: function(){
        if(this.brand.logo == null){
            return "Select File";
        }
        if(this.brand.logo == null){
            return "File Selected";
        }
        return this.brand.logo.name;
    },
  }
}
</script>